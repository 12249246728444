<template>
  <b-container class="diagonal-gradient-section" fluid>
    <b-row class="px-2 animated-section">
      <b-col md="5">
        <img :src="headerImage" alt="Left" class="img-fluid header-image">
      </b-col>
      <b-col md="7" class="d-flex flex-column justify-content-center">
        <h1 class="header-text">Tingkatkan Pengalaman</h1> 
        <h1 class="header-text">dan Keterampilan Praktis Kamu</h1>
        <h1 class="header-text">Kapan dan Dimana Saja</h1>
        <p class="mt-3 lead-text">
          Dapatkan Akses Tak Terbatas pada Pembelajaran Desain Teknik dengan Konten Terbaru bersama MechTech Indonesia.
        </p>
      </b-col>
    </b-row>

    <b-row class="mx-2 mx-md-5 mt-4">
      <b-col>
        <b-card class="rounded-border">
          <b-card-body class="p-0">
            <b-row>
              <b-col md="4" class="d-flex justify-content-center">
                <b-img :src="valuePropositionSrc[0]" class="w-50" />
              </b-col>
              <b-col md="8" class="d-flex align-items-center">
                <p class="value-proposition-text">Akses Tak Terbatas ke <br class="d-none d-md-block"> Semua Video Pembelajaran</p>
              </b-col>
            </b-row>
          </b-card-body>
        </b-card>
      </b-col>
      <b-col>
        <b-card class="rounded-border">
          <b-card-body class="p-0">
            <b-row>
              <b-col md="4" class="d-flex justify-content-center">
                <b-img :src="valuePropositionSrc[1]" class="w-50" />
              </b-col>
              <b-col md="8" class="d-flex align-items-center">
                <p class="value-proposition-text">Sertifikat Pengakuan <br class="d-none d-md-block"> Kompetensi Kelulusan</p>
              </b-col>
            </b-row>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
    <b-row class="mx-2 mx-md-5">
      <b-col>
        <b-card class="rounded-border">
          <b-card-body class="p-0">
            <b-row>
              <b-col md="4" class="d-flex justify-content-center">
                <b-img :src="valuePropositionSrc[2]" class="w-50" />
              </b-col>
              <b-col md="8" class="d-flex align-items-center">
                <p class="value-proposition-text">Akses Tak Terbatas ke <br class="d-none d-md-block"> Semua Video Pembelajaran</p>
              </b-col>
            </b-row>
          </b-card-body>
        </b-card>
      </b-col>
      <b-col>
        <b-card class="rounded-border">
          <b-card-body class="p-0">
            <b-row>
              <b-col md="4" class="d-flex justify-content-center">
                <b-img :src="valuePropositionSrc[3]" class="w-50" />
              </b-col>
              <b-col md="8" class="d-flex align-items-center">
                <p class="value-proposition-text">Dukungan Komunitas <br class="d-none d-md-block"> Profesional</p>
              </b-col>
            </b-row>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>

    <b-row class="mt-5 mb-5 pt-5">
      <b-col class="d-flex flex-column align-items-center">
        <h2 class="heading-text-white">
          Pilih Kelas sebagai investasi belajar
        </h2>
        <h2 class="heading-text-white">
          yang sesuai dengan kebutuhan Kamu!
        </h2>
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <b-row>
          <b-col md="2" />
          <b-col md="4" class="px-2">
            <b-card class="card-class-rounded h-100">
              <b-row class="h-100">
                <b-col class="d-flex flex-column">
                  <img :src="classSrc[0]" class="card-img-top rounded fixed-size" alt="Card">
                  <h5 class="mt-2 value-proposition-text">Introduction to Ansys</h5>
                  <p class="card-class-description mt-2">Pahami dan kuasai dasar teori Ansys dan penerapannya agar kamu lebih mudah untuk belajar teori lanjutannya!</p>
                  <b-button size="md" block class="btn-custom mt-auto" href="https://mechtechidn.myr.id/course/introduction-to-ansys" target="_blank">
                    <h4 class="text-white">Daftar Kelas</h4>
                  </b-button>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
          <b-col md="4" class="px-2 mt-2 mt-md-0">
            <b-card class="card-class-rounded h-100">
              <b-row class="h-100">
                <b-col class="d-flex flex-column">
                  <img :src="classSrc[1]" class="card-img-top rounded fixed-size" alt="Card">
                  <h5 class="mt-2 value-proposition-text">Introduction to AutoCAD</h5>
                  <p class="card-class-description mt-2">Belajar dasar-dasar Tools penting pada AutoCAD untuk pemula.</p>
                  <b-button size="md" block class="btn-custom mt-auto" href="https://mechtechidn.mayar.link/" target="_blank">
                    <h4 class="text-white">Daftar Kelas</h4>
                  </b-button>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
          <b-col md="2" />
        </b-row>
        <b-row class="mt-2">
          <b-col md="2" />
          <b-col md="4" class="px-2">
            <b-card class="card-class-rounded h-100">
              <b-row class="h-100">
                <b-col class="d-flex flex-column">
                  <img :src="classSrc[2]" class="card-img-top rounded fixed-size" alt="Card">
                  <h5 class="mt-2 value-proposition-text">AutoCAD Mechanical for Mechanical Engineers</h5>
                  <p class="card-class-description mt-2">Mahir menggambar objek mechanical secara 2D dan 3D dengan lebih mudah.</p>
                  <b-button size="md" block class="btn-custom mt-auto" href="https://mechtechidn.myr.id/course/autocad-mechanical-for-mechanical-engineers-level-beginner" target="_blank">
                    <h4 class="text-white">Daftar Kelas</h4>
                  </b-button>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
          <b-col md="4" class="px-2 mt-2 mt-md-0">
            <b-card class="card-class-rounded h-100">
              <b-row class="h-100">
                <b-col class="d-flex flex-column">
                  <img :src="classSrc[3]" class="card-img-top rounded fixed-size" alt="Card">
                  <h5 class="mt-2 value-proposition-text">Engineering Drawing for Mechanical Engineer with Solidworks</h5>
                  <p class="card-class-description mt-2">Belajar teori pada Solidworks untuk pemula.</p>
                  <b-button size="md" block class="btn-custom mt-auto" href="https://mechtechidn.myr.id/course/engineering-drawing-for-mechanical-engineer-with-solidworks" target="_blank">
                    <h4 class="text-white">Daftar Kelas</h4>
                  </b-button>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
          <b-col md="2" />
        </b-row>
      </b-col>
    </b-row>


    <b-row class="mt-0 mt-md-5">
      <b-col />
    </b-row>
    <b-row class="mt-0 mt-md-5">
      <b-col />
    </b-row>

    <b-row class="background-image upper-footer" :style="{ backgroundImage: 'url(' + backgroundImage + ')'}">
      <b-col>
        <b-row class="mt-0 mt-md-5 pt-5">
          <b-col lg="1" />
          <b-col lg="5">
            <b-row class="mt-5">
              <b-col>
                <h1 class="reviews-header-text">Kata Sobat Techie <br> Tentang MechTech Indonesia</h1>
              </b-col>
            </b-row>
            <b-row class="mt-2">
              <b-col>
                <p class="reviews-desc-text">Fokus utama kami adalah memberikan experience <br> belajar terbaik untuk student kami.</p>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-img :src="reviewsImage" class="w-75" />
              </b-col>
            </b-row>
          </b-col>
          <b-col lg="6" class="px-2">
            <b-row class="mt-4 mt-md-0">
              <b-col lg="5">
                <b-row>
                  <b-col>
                    <b-card class="review-card">
                      <b-row>
                        <b-col lg="3" class="d-flex justify-content-center align-items-center">
                          <b-img rounded="circle" :src="reviewSrc[0]" class="review-card-participant-photo" />
                        </b-col>
                        <b-col lg="9">
                          <h3>Hildan Fahrizal Nur Faurizki</h3>
                          <span>Universitas Negeri Malang</span>
                        </b-col>
                      </b-row>
                      <b-row class="mt-2">
                        <b-col>
                          <p class="review-card-text">
                            Kelas Fundamentalnya mantap! 
                            Tentornya helpfull banget, belajar sambil diselingi tambahan pengetahuan tentang 
                            software teknik yang lain, maupun industri teknik sendiri. Cocok banget buat 
                            yang yang mau belajar dari 0 sampai jago!
                          </p>
                        </b-col>
                      </b-row>
                    </b-card>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <b-card class="review-card">
                      <b-row>
                        <b-col lg="3" class="d-flex justify-content-center align-items-center">
                          <b-img rounded="circle" :src="reviewSrc[1]" class="review-card-participant-photo" />
                        </b-col>
                        <b-col lg="9">
                          <h3>Natasya Habibah</h3>
                          <span>Universitas Pertahanan</span>
                        </b-col>
                      </b-row>
                      <b-row class="mt-2">
                        <b-col>
                          <p class="review-card-text">
                            Setiap pelatihan di MechTech bikin belajar hal-hal baru yang sebelumnya terasa sulit jadi 
                            lebih mudah. Belajar hal basic yang gitu-gitu aja jadi lebih menarik karena Mentor-nya
                            TOP dan ada grup diskusinya juga. Jadi bisa nambah teman diluar kampus!
                          </p>
                        </b-col>
                      </b-row>
                    </b-card>
                  </b-col>
                </b-row>
              </b-col>
              <b-col lg="5">
                <b-row class="mt-0 mt-md-5">
                  <b-col>
                    <b-card class="review-card">
                      <b-row>
                        <b-col lg="3" class="d-flex justify-content-center align-items-center">
                          <b-img rounded="circle" :src="reviewSrc[2]" class="review-card-participant-photo" />
                        </b-col>
                        <b-col lg="9">
                          <h3>Daniar Agistya Wardhana</h3>
                          <span>Institut Teknologi Sepuluh November</span>
                        </b-col>
                      </b-row>
                      <b-row class="mt-2">
                        <b-col>
                          <p class="review-card-text">
                            Kelas Fundamentalnya mantap! 
                            Rangkaian kelas disini juara banget sih! 
                            Mentor sabar & telaten setiap menjawab pertanyaan bahkan
                            diluar kelas juga. Penguasaan setiap materinya juga TOP banget! 
                            Good job, MechTech!
                          </p>
                        </b-col>
                      </b-row>
                    </b-card>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <b-card class="review-card">
                      <b-row>
                        <b-col lg="3" class="d-flex justify-content-center align-items-center">
                          <b-img rounded="circle" :src="reviewSrc[3]" class="review-card-participant-photo" />
                        </b-col>
                        <b-col lg="9">
                          <h3>Mogah Sagacious Saragih</h3>
                          <span>Institut Teknologi Sepuluh November</span>
                        </b-col>
                      </b-row>
                      <b-row class="mt-2">
                        <b-col>
                          <p class="review-card-text">
                            Belajar dengan MechTech sangat membuat diri Saya improve di banyak hal, terutama dalam desain & simulasi di aplikasinya,
                            tidak lupa juga teori-teori yang mendukungnya diberikan dengan cukup lengkap sehingga sangat membantu
                          </p>
                        </b-col>
                      </b-row>
                    </b-card>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </b-col>
        </b-row>

        <b-row class="mt-5 pb-5">
          <b-col class="px-2 px-md-4">
            <FrequentlyAskedSubscription />
          </b-col>
        </b-row>

        <b-row class="background-image upper-footer">
          <b-col>
            <b-row class="mt-5">
              <b-col lg="6" md="6" sm="6" class="d-flex justify-content-center align-items-center">
                <b-img :src="phoneMockup" class="w-50" />
              </b-col>
              <b-col lg="6" md="6" sm="6" class="d-flex align-items-center">
                <b-row class="pl-2 mt-2">
                  <b-col>
                    <h1 class="upper-footer-text-sm">Jadilah Yang Pertama Tahu</h1>
                    <h1 class="upper-footer-text-lg">Follow Instagram kami</h1>
                    <b-button size="lg" class="mt-2 rounded-pill" variant="light" href="https://www.instagram.com/mechtech.class/" target="_blank">
                      <h1 class="follow-button-text">Follow >></h1>
                    </b-button>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>

            <b-row class="pt-5 pb-3">
              <b-col class="text-center">
                <b-row class="mt-5">
                  <b-col />
                </b-row>
                <b-row class="mt-5">
                  <b-col />
                </b-row>
                <p style="color: white; font-weight: bold;">&copy; 2024 MechTech Indonesia. All rights reserved.</p>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import FrequentlyAskedSubscription from '@core/components/FrequentlyAskedSubscription.vue'

export default {
  name: "SoftwareCourseLanding",
  components: {
    FrequentlyAskedSubscription,
  },
  data() {
    return {
      headerImage: require('@/assets/images/mechtech/landing-subscription-class/subscription-header.png'),
      valuePropositionSrc: [
        require('@/assets/images/mechtech/landing-subscription-class/subscription-value-1.png'),
        require('@/assets/images/mechtech/landing-subscription-class/subscription-value-2.png'),
        require('@/assets/images/mechtech/landing-subscription-class/subscription-value-3.png'),
        require('@/assets/images/mechtech/landing-subscription-class/subscription-value-4.png'),
      ],
      cardPlaceholderImage: require('@/assets/images/mechtech/landing-subscription-class/subscription-card-placeholder.png'),
      reviewsImage: require('@/assets/images/mechtech/landing-subscription-class/subscription-review.png'),
      reviewSrc: [
        require('@/assets/images/mechtech/review-image/logo-um-smaller.png'),
        require('@/assets/images/mechtech/review-image/logo-pertahanan-smaller.png'),
        require('@/assets/images/mechtech/review-image/logo-its-smaller.png'),
        require('@/assets/images/mechtech/review-image/logo-its-smaller.png'),
      ],
      classSrc: [
        require('@/assets/images/mechtech/landing-subscription-class/subscription-class-ansys.png'),
        require('@/assets/images/mechtech/landing-subscription-class/subscription-class-autocad-intro.jpeg'),
        require('@/assets/images/mechtech/landing-subscription-class/subscription-class-autocad.png'),
        require('@/assets/images/mechtech/landing-subscription-class/subscription-class-solidworks.png'),
      ],
      phoneMockup: require('@/assets/images/mechtech/phone-mockup.png'),
      backgroundImage: require('@/assets/images/mechtech/landing-subscription-class/subscription-background-image.png'),
    };
  },
  methods: {
  }
};
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/themes/bordered-layout.scss";

.header-image {
  max-width: 100%;
  height: auto;
}

@media (max-width: 576px) {
  .header-image {
    width: 80%;
  }
}

.header-text {
  font-weight: bold;
  font-size: 2rem;
  color: white;

  @include media-breakpoint-up(sm) {
    font-size: 2rem;
  }
  @include media-breakpoint-up(md) {
    font-size: 4.4rem;
  }
  @include media-breakpoint-up(lg) {
    font-size: 4.4rem;
  }
  @include media-breakpoint-up(xl) {
    font-size: 4.4rem;
  }
}

.lead-text {
  text-align: left;
  font-size: 1.25rem;
  line-height: 1.25;
  color: white;

  @include media-breakpoint-up(sm) {
    font-size: 1.25rem;
  }
  @include media-breakpoint-up(md) {
    font-size: 2.25rem;
  }
  @include media-breakpoint-up(lg) {
    font-size: 2.25rem;
  }
  @include media-breakpoint-up(xl) {
    font-size: 2.25rem;
  }
}

.value-proposition-text {
  font-size: 1.25rem;
  font-weight: 600;
  line-height: 1.25;

  @include media-breakpoint-up(sm) {
    font-size: 1.25rem;
  }
  @include media-breakpoint-up(md) {
    font-size: 2rem;
  }
  @include media-breakpoint-up(lg) {
    font-size: 2rem;
  }
  @include media-breakpoint-up(xl) {
    font-size: 2rem;
  }
}

.heading-text-white {
  font-weight: 600;
  font-size: 1.8rem;
  color: white;

  @include media-breakpoint-up(sm) {
    font-size: 1.8rem;
  }
  @include media-breakpoint-up(md) {
    font-size: 2.5rem;
  }
  @include media-breakpoint-up(lg) {
    font-size: 2.5rem;
  }
  @include media-breakpoint-up(xl) {
    font-size: 2.5rem;
  }
}

.rounded-border {
  border-radius: 20px;
}


.btn-custom {
    background-color: #0E224C !important;
    color: white;
    border: none;
}

.btn-custom:hover {
    background-color: #091836 !important; /* Darken the color by 10% */
    color: white;
}

.card-class-description {
  text-align: left;
  font-size: 1rem;
  line-height: 1.25;

  @include media-breakpoint-up(sm) {
    font-size: 1rem;
  }
  @include media-breakpoint-up(md) {
    font-size: 1.5rem;
  }
  @include media-breakpoint-up(lg) {
    font-size: 1.5rem;
  }
  @include media-breakpoint-up(xl) {
    font-size: 1.5rem;
  }
}

.card-class-rounded {
  border-radius: 15px;
}

.review-card-text {
  font-size: 1rem;
  line-height: 1.25;

  @include media-breakpoint-up(sm) {
    font-size: 1rem;
  }
  @include media-breakpoint-up(md) {
    font-size: 1.4rem;
  }
  @include media-breakpoint-up(lg) {
    font-size: 1.4rem;
  }
  @include media-breakpoint-up(xl) {
    font-size: 1.4rem;
  }
}
.review-card-participant-photo {
  width: 75px;
}

.reviews-header-text {
  font-size: 32px;
  font-weight: bold;
  line-height: 1.25;
  color: white;
}

.reviews-desc-text {
  font-size: 22px;
  font-weight: 500;
  line-height: 1.25;
  color: white;
}


.upper-footer-text-sm {
  font-weight: 600;
  font-size:  1.5rem;
  line-height: 1.25;
  color: white;

  @include media-breakpoint-up(sm) {
    font-size: 1.5rem;
  }
  @include media-breakpoint-up(md) {
    font-size: 3rem;
  }
  @include media-breakpoint-up(lg) {
    font-size: 3rem;
  }
  @include media-breakpoint-up(xl) {
    font-size: 3rem;
  }
}
.upper-footer-text-lg {
  font-weight: 600;
  font-size: 2.5rem;
  line-height: 1.25;
  color: white;

  @include media-breakpoint-up(sm) {
    font-size: 2.5rem;
  }
  @include media-breakpoint-up(md) {
    font-size: 4.5rem;
  }
  @include media-breakpoint-up(lg) {
    font-size: 4.5rem;
  }
  @include media-breakpoint-up(xl) {
    font-size:4.5rem;
  }
}

.follow-button-text {
  font-weight: 600;
  color: #0E224C;
}

.background-image {
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.diagonal-gradient-section {
  position: relative;
  background: linear-gradient(to bottom right, #C14747 10%, #A30F0F 50%, #CB1212 100%);
}

.fixed-size {
  max-width: 100%;
  max-height: 350px;
}

.animated-section {
  animation: fadeInUp 1s ease-out;
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
</style>
