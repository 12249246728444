<template>
  <b-card class="rounded-lg">
    <b-card-body>
      <b-row>
        <b-col lg="5" class="d-flex align-items-center">
          <b-row>
            <b-col>
              <h1 class="faq-header">Frequently Asked Question</h1>
              <p class="faq-desc mt-2">Berikut beberapa pertanyaan yang mungkin ingin kamu tanyakan ke kami, kalau masih penasaran langsung saja hubungi admin!</p>
              <b-button size="lg" class="mt-2 btn-custom rounded-pill" variant="dark" href="https://wa.me/6282334433773" target="_blank">
                <h2 class="text-white font-weight-bold">Hubungi Admin</h2>
              </b-button>
            </b-col>
          </b-row>
        </b-col>
        <b-col lg="7" class="faq-right-content my-2">
          <div class="accordion" role="tablist">
            <b-row>
              <b-col cols="12" class="p-1" role="tab">
                <h5 v-b-toggle="'accordion-1'" class="mb-0 faq-points-header" style="cursor: pointer;">
                  Bagaimana cara berlangganan Subscription Class?
                </h5>
                <hr class="mb-0">
              </b-col>
              <b-collapse id="accordion-1" accordion="my-accordion" role="tabpanel" class="p-1">
                <b-row>
                  <b-col>
                    <p class="faq-points-text">
                      Kamu dapat berlangganan melalui halaman Subscription Class di situs kami dan memilih paket yang sesuai dengan kebutuhan.
                    </p>
                  </b-col>
                </b-row>
              </b-collapse>
            </b-row>

            <b-row>
              <b-col cols="12" class="p-1" role="tab">
                <h5 v-b-toggle="'accordion-2'" class="mb-0 faq-points-header" style="cursor: pointer;">
                  Apakah saya bisa membatalkan langganan Subscription Class kapan saja?
                </h5>
                <hr class="mb-0">
              </b-col>
              <b-collapse id="accordion-2" accordion="my-accordion" role="tabpanel" class="p-1">
                <b-row>
                  <b-col>
                    <p class="faq-points-text">
                      Kamu bisa membatalkan langganan kapan saja. Namun, akses ke kelas akan berakhir pada akhir periode berlangganan saat ini.
                    </p>
                  </b-col>
                </b-row>
              </b-collapse>
            </b-row>

            <b-row>
              <b-col cols="12" class="p-1" role="tab">
                <h5 v-b-toggle="'accordion-3'" class="mb-0 faq-points-header" style="cursor: pointer;">
                  Bisakah saya mengakses kursus di perangkat seluler/handphone?
                </h5>
                <hr class="mb-0">
              </b-col>
              <b-collapse id="accordion-3" accordion="my-accordion" role="tabpanel" class="p-1">
                <b-row>
                  <b-col>
                    <p class="faq-points-text">
                      Tentu, kamu bisa mengakses materi kursus melalui perangkat mobile baik handphone maupun tablet
                    </p>
                  </b-col>
                </b-row>
              </b-collapse>
            </b-row>

            <b-row>
              <b-col cols="12" class="p-1" role="tab">
                <h5 v-b-toggle="'accordion-4'" class="mb-0 faq-points-header" style="cursor: pointer;">
                  Apakah saya akan mendapatkan sertifikat setelah menyelesaikan kursus?
                </h5>
                <hr class="mb-0">
              </b-col>
              <b-collapse id="accordion-4" accordion="my-accordion" role="tabpanel" class="p-1">
                <b-row>
                  <b-col>
                    <p class="faq-points-text">
                      Tentu, kamu akan mendapatkan sertifikat yang bisa diunduh sebagai bukti keahlian yang telah Kamu capai dengan kelengkapan dan kesesuaian tugas, serta kehadiran penuh 100%.
                    </p>
                  </b-col>
                </b-row>
              </b-collapse>
            </b-row>
          </div>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
export default {
  data() {
    return {
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";

  .faq-right-content {
    border-left: 3px solid #C9C9C9;
  }

  .faq-header {
    font-size: 1.8rem;
    color: #0E224C;
    font-weight: 700;
    line-height: 1.25;

    @include media-breakpoint-up(sm) {
      font-size: 1.8rem;
    }
    @include media-breakpoint-up(md) {
      font-size: 2.5rem;
    }
    @include media-breakpoint-up(lg) {
      font-size: 2.5rem;
    }
    @include media-breakpoint-up(xl) {
      font-size: 2.5rem;
    }
  }

  .faq-desc {
    font-size: 1.5rem;
    color: #686868;
    line-height: 1.25;

    @include media-breakpoint-up(sm) {
      font-size: 1.5rem;
    }
    @include media-breakpoint-up(md) {
      font-size: 1.75rem;
    }
    @include media-breakpoint-up(lg) {
      font-size: 1.75rem;
    }
    @include media-breakpoint-up(xl) {
      font-size: 1.75rem;
    }
  }

  h2 {
    font-size: 1.25rem;

    @include media-breakpoint-up(sm) {
      font-size: 1.25rem;
    }
    @include media-breakpoint-up(md) {
      font-size: 1.75rem;
    }
    @include media-breakpoint-up(lg) {
      font-size: 1.75rem;
    }
    @include media-breakpoint-up(xl) {
      font-size: 1.75rem;
    }
  }

  .btn-custom {
      background-color: #0E224C !important;
      color: white;
      border: none;
  }

  .btn-custom:hover {
      background-color: #091836 !important; /* Darken the color by 10% */
      color: white;
  }

  .faq-points-header {
    font-size: 1.25rem;
    line-height: 1.25;

    @include media-breakpoint-up(sm) {
      font-size: 1.25rem;
    }
    @include media-breakpoint-up(md) {
      font-size: 1.75rem;
    }
    @include media-breakpoint-up(lg) {
      font-size: 1.75rem;
    }
    @include media-breakpoint-up(xl) {
      font-size: 1.75rem;
    }
  }
  .faq-points-text {
    font-size: 1rem;
    line-height: 1.25;

    @include media-breakpoint-up(sm) {
      font-size: 1rem;
    }
    @include media-breakpoint-up(md) {
      font-size: 1.25rem;
    }
    @include media-breakpoint-up(lg) {
      font-size: 1.25rem;
    }
    @include media-breakpoint-up(xl) {
      font-size: 1.25rem;
    }
  }
</style>
